export default {
	path: "/organiser",
	component: () => import("@/view/organiser/base"),
	meta: {
		protected: true,
	},
	children: [
		{
			path: "/",
			name: "organiser.index",
			component: () => import("@/view/organiser/index"),
		},
		{
			path: "past-events",
			name: "organiser.past",
			component: () => import("@/view/organiser/past-events"),
		},
	],
};
