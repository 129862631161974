// import {SearchAPI} from "./api"
/**
 *
 */
const state = {
	results: [],
	// filters: null,
	result: null,
	// suggestions: [],
};
/**
 *
 */
const getters = {
	results(state) {
		return state.results;
	},
	// suggestions(state) {
	// 	return state.suggestions;
	// },
};
/**
 *
 */
const mutations = {
	setResult(state, result) {
		state.result = result;
	},
	// setSuggestions(state, suggestions) {
	// 	state.suggestions = suggestions;
	// },
};
/**
 *
 */
const actions = {
	fetchDiscoveryResults: async function() {
		/**
		 * Discovery results are a feature collection that are to be placed on a map.
		 */
		return {
			"type": "FeatureCollection",
			"features": [
				{
					"type": "Feature",
					"properties": {
						"type": "Event",
						"iconSize": [24, 24]
					},
					"geometry": {
						"type": "Point",
						"coordinates": [-0.37932022361020223, 50.822269899999995],
					}
				},
				{
					"type": "Feature",
					"properties": {
						"type": "Business",
						"iconSize": [24, 24]
					},
					"geometry": {
						"type": "Point",
						"coordinates": [-0.3681372, 50.8146806]
					}
				},
				{
					"type": "Feature",
					"properties": {
						"type": "Entrepreneur",
						"iconSize": [24, 24]
					},
					"geometry": {
						"type": "Point",
						"coordinates": [-0.3916056, 50.8250198],
					}
				}
			],
		};
	},
	fetchDiscoverySuggestions: async function() {
		return [
			"fashion connoisseur",
			"fashion designer",
			"Fashion doctor",
			"fashion research",
		];
		/*
		return [
			"Alabama",
			"Alaska",
			"American Samoa",
			"Arizona",
			"Arkansas",
			"California",
			"Colorado",
			"Connecticut",
			"Delaware",
			"District of Columbia",
			"Federated States of Micronesia",
			"Florida",
			"Georgia",
			"Guam",
			"Hawaii",
			"Idaho",
			"Illinois",
			"Indiana",
			"Iowa",
			"Kansas",
			"Kentucky",
			"Louisiana",
			"Maine",
			"Marshall Islands",
			"Maryland",
			"Massachusetts",
			"Michigan",
			"Minnesota",
			"Mississippi",
			"Missouri",
			"Montana",
			"Nebraska",
			"Nevada",
			"New Hampshire",
			"New Jersey",
			"New Mexico",
			"New York",
			"North Carolina",
			"North Dakota",
			"Northern Mariana Islands",
			"Ohio",
			"Oklahoma",
			"Oregon",
			"Palau",
			"Pennsylvania",
			"Puerto Rico",
			"Rhode Island",
			"South Carolina",
			"South Dakota",
			"Tennessee",
			"Texas",
			"Utah",
			"Vermont",
			"Virgin Island",
			"Virginia",
			"Washington",
			"West Virginia",
			"Wisconsin",
			"Wyoming",
		];
		*/
	},

/*
	async fetchResult({commit}, params) {
		const {data} = await SearchAPI.fetchResults(params);
		commit("setResult", data);
		return data;
	},
	async fetchSuggestions({commit}, params) {
		const {data} = await SearchAPI.fetchSuggestions(params);
		return data;
	},
*/
};
/**
 *
 */
export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
