import Vue from "vue";
import Vuex from "vuex";
import page from "@/store/page.js"
import search from "@/store/search.js"

Vue.use(Vuex);

export default new Vuex.Store({
	strict: process.env.NODE_ENV !== "production",
	modules: {
		page,
		search,
	}
});
