export default {
	path: "/account/",
	component: () => import("@/view/account/base"),
	redirect: {
		name: "account.profile"
	},
	meta: {
		protected: true,
	},
	children: [
		{
			path: "profile",
			name: "account.profile",
			component: () => import("@/view/account/profile"),
		},
		{
			path: "settings",
			name: "account.settings",
			component: () => import("@/view/account/settings"),
		},
		{
			path: "logout",
			name: "account.logout",
		},
	],
};
