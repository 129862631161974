import Vue from "vue"
import VueRouter from "vue-router"
import account from "@/router/account.js"
import businesses from "@/router/businesses.js"
import discover from "@/router/discover.js"
import development from "@/router/development.js"
import onboarding from "@/router/onboarding.js"
import organiser from "@/router/organiser.js"
import projects from "@/router/projects.js"

Vue.use(VueRouter)

export default new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes: [
		account,
		businesses,
		discover,
		development,
		onboarding,
		organiser,
		projects,
	]
});
