export default {
	path: "/businesses",
	component: () => import("@/view/businesses/base"),
	meta: {
		protected: true,
	},
	children: [
		{
			path: "/",
			name: "businesses.index",
			component: () => import("@/view/businesses/index"),
		},
		{
			path: "all",
			name: "businesses.all",
			component: () => import("@/view/businesses/all"),
		},
		{
			path: "partnerships",
			name: "businesses.partnerships",
			component: () => import("@/view/businesses/partnerships"),
		},
		{
			path: ":id/",
			name: "businesses.instance",
			component: () => import("@/view/businesses/instance"),
		},
	],
};
