/**
 *
 */
const state = {
	sidebar: {
		visible: false,
	},
	snackbar: {
		visible: false,
		timeout: 6000,
		message: null,
		button: {
			text: null,
			icon: null,
			clicked: null,
		}
	},
};
/**
 *
 */
const getters = {
	sidebar: state => state.sidebar,
	snackbar: state => state.snackbar,
};
/**
 *
 */
const mutations = {
	hideSidebar(state) {
		state.sidebar.visible = false;
	},
	showSidebar(state) {
		state.sidebar.visible = true;
	},
	hideSnackbar(state) {
		state.snackbar.visible = false;
	},
	showSnackbar(state, {message, timeout, button}) {
		state.snackbar.message = message;
		state.snackbar.timeout = timeout;
		state.snackbar.button = button;
		state.snackbar.visible = true;
	},
};
/**
 *
 */
const actions = {
};
/**
 *
 */
export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
