export default {
	path: "/projects",
	component: () => import("@/view/projects/base"),
	meta: {
		protected: true,
	},
	children: [
		{
			path: "/",
			name: "projects.index",
			component: () => import("@/view/projects/index"),
		},
		{
			path: "all",
			name: "projects.all",
			component: () => import("@/view/projects/all"),
		},
		{
			path: "partnerships",
			name: "projects.partnerships",
			component: () => import("@/view/projects/partnerships"),
		},
		{
			path: "development",
			name: "projects.development",
			component: () => import("@/view/projects/development"),
		},
		{
			path: ":id/",
			name: "projects.instance",
			component: () => import("@/view/projects/instance"),
		},
	],
};
