<template>
<v-snackbar bottom right v-model="snackbar.visible" :timeout="snackbar.timeout" @input="onInputChanged">
	{{snackbar.message}}
	<template v-slot:action="{attrs}">
	<v-btn v-if="hasButtonText()" v-bind="attrs" @click="onClicked" text small color="yellow">{{snackbar.button.text}}</v-btn>
	<v-btn v-else v-bind="attrs" @click="hideSnackbar" icon color="yellow">
		<v-icon v-if="hasButtonIcon()">{{snackbar.button.icon}}</v-icon>
		<v-icon v-else>mdi-close</v-icon>
	</v-btn>
	</template>
</v-snackbar>
</template>


<script>
import {mapGetters, mapMutations} from "vuex";
export default {
	computed: {
		...mapGetters("page", [
			"snackbar",
		]),
	},
	methods: {
		...mapMutations("page", [
			"hideSnackbar",
		]),
		onInputChanged(value) {
			if (value === false) {
				this.hideSnackbar();
			}
		},
		onClicked() {
			this.hideSnackbar();
			if (this.snackbar.button.clicked) {
				this.snackbar.button.clicked();
			}
		},
		hasButtonText() {
			return this.snackbar.button !== null && this.snackbar.button.text !== null && this.snackbar.button.text.length > 0;
		},
		hasButtonIcon() {
			return this.snackbar.button !== null && this.snackbar.button.icon !== null;
		},
	},
}
</script>
