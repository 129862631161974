export default {
	path: "/discover",
	component: () => import("@/view/discover/base"),
	meta: {
		protected: true,
	},
	children: [
		{
			path: "/",
			name: "discover.index",
			component: () => import("@/view/discover/index"),
		},
	],
};
