export default {
	path: "/development",
	component: () => import("@/view/development/base"),
	meta: {
		protected: true,
	},
	children: [
		{
			path: "/",
			name: "development.index",
			component: () => import("@/view/development/index"),
		},
		{
			path: "courses",
			name: "development.courses",
			component: () => import("@/view/development/courses/index"),
		},
		{
			path: "achievements",
			name: "development.achievements",
			component: () => import("@/view/development/achievements"),
		},
		{
			path: "labs",
			name: "development.labs",
			component: () => import("@/view/development/labs/index"),
		},
		{
			path: "resources",
			name: "development.resources",
			component: () => import("@/view/development/resources/index"),
		},
	],
};
