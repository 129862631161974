<style scoped>
.menu-btn .show-on-active,
.menu-btn.active .hide-on-active {
	display: none !important;
}
.menu-btn.active .show-on-active {
	display: inline !important;
}
</style>


<template>
<v-bottom-navigation app grow color="brown darken-2" background-color="brown lighten-4">
	<v-btn v-for="page in pages" :key="page.to"
	class="menu-btn"
	active-class="active"
	small
	icon
	plain
	:disabled="page.disabled"
	:to="{name: page.to}">
		<span>{{page.name}}</span>
		<v-icon color="brown" class="show-on-active">{{page.icon}}</v-icon>
		<v-icon color="brown" class="hide-on-active">{{page.icon}}-outline</v-icon>
	</v-btn>
</v-bottom-navigation>
</template>


<script>
export default {
	data: function () {
		return {
			pages: [
				{
					name: "Development",
					icon: "mdi-sprout",
					to: "development.index",
				},
				{
					name: "Projects",
					icon: "mdi-clipboard-text",
					to: "projects.index",
				},
				{
					name: "Businesses",
					icon: "mdi-handshake",
					to: "businesses.index",
				},
				{
					name: "Discover",
					icon: "mdi-compass",
					to: "discover.index",
				},
				{
					name: "Organiser",
					icon: "mdi-calendar",
					to: "organiser.index",
				},
			],
		}
	},
}
</script>
