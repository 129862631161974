<style scoped>
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	webkit-font-smoothing: antialiased;
	moz-osx-font-smoothing: grayscale;
	color: #2c3e50;
}
.v-application--wrap {
	min-height: 100% !important;
}
</style>


<style>
.text-pre-wrap {
	pre-wrap: whitespace;
}
</style>


<template>
<v-app>
	<Navigation/>
	<BottomNavigation class="d-flex d-md-none"/>
	<Snackbar key="snackbar"/>
	<v-main class="brown lighten-5">
		<v-container fluid fill-height class="pa-0">
			<!-- <v-fade-transition mode="out-in"> -->
				<router-view class="fill-height no-gutters"/>
			<!-- </v-fade-transition> -->
		</v-container>
	</v-main>
</v-app>
</template>


<script>
import Navigation from "@/components/Navigation"
import BottomNavigation from "@/components/BottomNavigation"
import Snackbar from "@/components/Snackbar"
export default {
	components: {
		Navigation,
		BottomNavigation,
		Snackbar,
	},
}
</script>
