import Vue from "vue";
import vuetify from "@/plugins/vuetify";
import router from "@/router/";
import store from "@/store/";
import app from "@/view/base";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	vuetify,
	render: h => h(app)
}).$mount("#app");
