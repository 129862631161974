- TODO Implement this: https://medium.com/@Taha_Shashtari/the-easy-way-to-change-page-title-in-vue-6caf05006863


<style scoped>
.menu-btn .show-on-active,
.menu-btn.active .hide-on-active {
	display: none !important;
}
.menu-btn.active .show-on-active {
	display: inline !important;
}
</style>


<template>
<v-app-bar
v-if="true"
color="brown lighten-4"
app
noutlined
elevate-on-scroll
clipped-left
clipped-right
dense>
	<v-row no-gutters align="center">
		<v-col md="3">
			<v-btn small icon plain class="mr-4 mb-1 d-xs-inline d-md-none">
				<v-icon color="brown" @click="showSidebar()">mdi-menu</v-icon>
			</v-btn>
			<router-link class="text-decoration-none text-title font-weight-bold grey--text text--lighten-5"
			style="letter-spacing: 3px !important"
			:to="{name: 'onboarding.index'}">HATCHIT</router-link>
		</v-col>
		<v-col md="6" class="text-center d-none d-md-inline">
			<v-btn v-for="page in pages" :key="page.to"
			class="menu-btn mx-6"
			active-class="active"
			small
			icon
			plain
			:disabled="page.disabled"
			:to="{name: page.to}">
				<v-icon color="brown" class="show-on-active">{{page.icon}}</v-icon>
				<v-icon color="brown" class="hide-on-active">{{page.icon}}-outline</v-icon>
			</v-btn>
		</v-col>
		<v-spacer></v-spacer>
		<v-btn small icon class="d-xs-inline d-md-none" :to="{name: 'account.profile'}">
			<v-avatar size="32"><v-img src="@/assets/profile.jpg"></v-img></v-avatar>
		</v-btn>
		<v-col md="3" class="text-end d-none d-md-inline">
			<v-chip class="brown lighten-1" dark :to="{name: 'account.profile'}">
				<v-avatar left><v-img src="@/assets/profile.jpg"></v-img></v-avatar>
				<span class="font-weight-bold">Minenhle</span>
			</v-chip>
		</v-col>
	</v-row>
</v-app-bar>
<v-navigation-drawer
v-else
app
floating
permanent
color="brown lighten-5"
:mini-variant="minimized">
	<v-list class="py-0" dense>
		<v-list-item v-for="menu in menus[0]" :key="menu.label">
			<v-list-item-action class="justify-center">
				<v-btn class="menu-btn" active-class="active" color="brown darken-1" icon :to="{name: menu.path}">
					<v-icon class="show-on-active">{{menu.icon}}</v-icon>
					<v-icon class="hide-on-active">{{menu.icon}}-outline</v-icon>
				</v-btn>
			</v-list-item-action>
			<v-list-item-content>
				<v-list-item-title>{{menu.label}}</v-list-item-title>
			</v-list-item-content>
		</v-list-item>
	</v-list>
	<template #append>
	<v-list class="py-0" dense>
		<v-list-item v-for="menu in menus[1]" :key="menu.label">
			<v-list-item-action class="justify-center">
				<v-btn class="menu-btn" active-class="active" color="brown darken-1" icon :to="{name: menu.path}">
					<v-icon class="show-on-active">{{menu.icon}}</v-icon>
					<v-icon class="hide-on-active">{{menu.icon}}-outline</v-icon>
				</v-btn>
			</v-list-item-action>
			<v-list-item-content>
				<v-list-item-title>{{menu.label}}</v-list-item-title>
			</v-list-item-content>
		</v-list-item>
	</v-list>
	</template>
</v-navigation-drawer>
</template>


<script>
import {mapMutations} from "vuex";
export default {
	data: function () {
		return {
			pages: [
				{
					icon: "mdi-sprout",
					to: "development.index",
				},
				{
					icon: "mdi-clipboard-text",
					to: "projects.index",
				},
				{
					icon: "mdi-handshake",
					to: "businesses.index",
				},
				{
					icon: "mdi-compass",
					to: "discover.index",
				},
				{
					icon: "mdi-calendar",
					to: "organiser.index",
				},
			],


			minimized: true,
			menus: [
				[
					{
						label: "Development",
						path: "development.index",
						icon: "mdi-sprout",
					},
					{
						label: "Projects",
						path: "projects.index",
						icon: "mdi-clipboard-text",
					},
					{
						label: "Discover",
						path: "discover.index",
						icon: "mdi-compass",
					},
					{
						label: "Account",
						path: "account.index",
						icon: "mdi-account-circle",
					},
				],
				[
					{
						label: "Organiser",
						path: "organiser.index",
						icon: "mdi-calendar-text",
					},
					// {
					// 	label: "Help",
					// 	path: "help.index",
					// 	icon: "mdi-help",
					// },
				],
			],
		}
	},
	methods: {
		...mapMutations("page", [
			"showSidebar",
		]),
	},
}
</script>
